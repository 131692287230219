@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --blue: #546fff;
  --lightBlue: #f6f7ff;
  --error: #d45553;
  --success: #29a176;
  --warning: #cd7b4d;
  --grey1: #1c1d1c;
  --grey2: #354454;
  --grey3: #6d7175;
  --grey4: #66788c;
  --grey5: #94a1af;
  --grey6: #6d7175;
  --title: #1c1d1c;
  --subtitle: #6d7175;
  --border: #e0e3e8;
}

body {
  margin: 0;
  font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
}
* {
  box-sizing: border-box;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}
:hover::-webkit-scrollbar-corner {
  background: inherit;
}

.intercom-lightweight-app,
.intercom-launcher-frame,
.intercom-launcher-badge-frame {
  z-index: 999 !important;
}

a {
  color: #546fff;
  text-decoration: none;
}

:global .root {
  max-width: 100%;
  padding: 16px;
  margin: auto;
}
:global .flex {
  display: flex;
}
:global .wrap {
  flex-wrap: wrap;
}
:global .alignCenter {
  align-items: center;
}
:global .flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .font-light {
  font-weight: 300;
}
:global .blueText {
  color: #546fff;
}
:global .pageHeader {
  color: #4a4a4a;
  font-size: 22px;
  font-weight: 300;
  margin: 0;
}
:global .grey2 {
  color: #2b2b2b;
}

.animate-highlight {
  @apply outline;
  animation: pulse 1s linear forwards;
}

@keyframes pulse {
  0% {
    outline-color: #546fff;
  }
  100% {
    outline-color: transparent;
  }
}
